import * as cornerstone from "@cornerstonejs/core";
import {
  Enums,
  init as csRenderInit,
  imageLoader,
  metaData,
  RenderingEngine,
} from "@cornerstonejs/core";
import {
  addTool,
  Enums as csToolsEnums,
  init as csToolsInit,
  utilities,
  PanTool,
  StackScrollMouseWheelTool,
  StackScrollTool,
  ToolGroupManager,
  WindowLevelTool,
  ZoomTool,
} from "@cornerstonejs/tools";
import registerWebImageLoader from "./web-image-loader";

registerWebImageLoader(imageLoader);
cornerstone.setUseCPURendering(true);
metaData.addProvider(metaDataProvider, 1000);

addTool(StackScrollTool);
addTool(StackScrollMouseWheelTool);
addTool(WindowLevelTool);
addTool(PanTool);
addTool(ZoomTool);

const initCornerstone = async () => {
  await csRenderInit();
  await csToolsInit();
};

function metaDataProvider(type, imageId) {
  return {
    imagePixelModule: {
      photometricInterpretation: "RGB",
    },
    generalSeriesModule: {},
    imagePlaneModule: {}
  }[type];
}

export class StackViewer {

  constructor(element){
    this.imageStackElement = element;
    this.imageIds = $(this.imageStackElement).data("stack-urls");
    this.effectiveCoverImageZIndex = $(this.imageStackElement).data("effective-cover-image-z-index")

    this.componentSize = $(this.imageStackElement).data("size");
    this.stackViewerElement = document.createElement("div");
    this.stackViewerElement.setAttribute("class", "stack_viewer");
    this.imageStackElement.appendChild(this.stackViewerElement);

    this.stackViewerElement.oncontextmenu = (e) => e.preventDefault();

    const self = this;
    initCornerstone().then(() => {
      self.postInitCornerstone();
    });

    this.initKeyboardNavigation();
  };

  isReady() {
    return this.viewport.viewportStatus === Enums.ViewportStatus.RENDERED;
  };

  canvasToWorld(point) {
    return this.viewport.canvasToWorld(point);
  };

  worldToCanvas(point) {
    return this.viewport.worldToCanvas(point);
  };

  addStackChangedEventListener(eventListener) {
    this.stackViewerElement.addEventListener(Enums.Events.IMAGE_RENDERED, eventListener);
    this.stackViewerElement.addEventListener(Enums.Events.CAMERA_MODIFIED, eventListener);
  };

  currentZIndex() {
    return this.viewport.currentImageIdIndex;
  };

  initKeyboardNavigation() {
    var self = this;
    document.addEventListener('keydown', (event) => {
      if (event.target.tagName.match(/^(INPUT|TEXTAREA|SELECT)$/)) return null;

      const z_index = self.currentZIndex();
      if (event.key === 'w') {
        this.viewport.setImageIdIndex(Math.min(this.imageIds.length - 1, z_index + 1));
      } else if (event.key === 's') {
        this.viewport.setImageIdIndex(Math.max(0, z_index - 1));
      };
    });
  };

  initToolGroup() {
    const toolGroupId = `myToolGroup-${this.imageStackElement.id}`;
    this.toolGroup = ToolGroupManager.createToolGroup(toolGroupId);

    this.toolGroup?.addTool(StackScrollTool.toolName);
    this.toolGroup?.addTool(StackScrollMouseWheelTool.toolName);
    this.toolGroup?.addTool(WindowLevelTool.toolName);
    this.toolGroup?.addTool(PanTool.toolName);
    this.toolGroup?.addTool(ZoomTool.toolName);

    this.toolGroup?.setToolActive(StackScrollMouseWheelTool.toolName);
    this.toolGroup?.setToolActive(StackScrollTool.toolName, {
      bindings: [
        {
          mouseButton: csToolsEnums.MouseBindings.Secondary,
          modifierKey: csToolsEnums.KeyboardBindings.Ctrl,
        },
      ],
    });
    this.toolGroup?.setToolActive(WindowLevelTool.toolName, {
      bindings: [
        {
          mouseButton: csToolsEnums.MouseBindings.Primary,
          modifierKey: csToolsEnums.KeyboardBindings.Alt,
        },
      ],
    });
    this.toolGroup?.setToolActive(PanTool.toolName, {
      bindings: [
        {
          mouseButton: csToolsEnums.MouseBindings.Primary,
          modifierKey: csToolsEnums.KeyboardBindings.Shift,
        },
        {
          mouseButton: csToolsEnums.MouseBindings.Secondary,
        },
      ],
    });
    this.toolGroup?.setToolActive(ZoomTool.toolName, {
      bindings: [
        {
          mouseButton: csToolsEnums.MouseBindings.Primary,
          modifierKey: csToolsEnums.KeyboardBindings.Ctrl,
        },
        {
          mouseButton: csToolsEnums.MouseBindings.Auxiliary,
        },
      ],
    });
  };

  postInitCornerstone() {
    this.initToolGroup();
    this.initRenderingEngineAndViewport();
  };

  initRenderingEngineAndViewport() {
    const renderingEngineId = `myRenderingEngine-${this.imageStackElement.id}`;
    const renderingEngine = new RenderingEngine(renderingEngineId);

    const viewportId = `imageStack-${this.imageStackElement.id}`;
    const { ViewportType } = Enums;
    const viewportInput = {
      viewportId: viewportId,
      element: this.stackViewerElement,
      type: ViewportType.STACK,
    };

    this.stackViewerElement.style.width = `${this.componentSize}px`;
    this.stackViewerElement.style.height = `${this.componentSize}px`;

    this.toolGroup?.addViewport(viewportId, renderingEngineId);

    renderingEngine.enableElement(viewportInput);

    this.viewport = renderingEngine.getViewport(viewportId);
    this.viewport.setStack(this.imageIds, this.effectiveCoverImageZIndex);
    this.viewport.render();

    utilities.stackPrefetch.enable(this.stackViewerElement);
  };
};

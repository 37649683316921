import * as stackViewer from "./stackViewer"
import cameraSvg from "./camera.svg"

export class RoiViewer {
  constructor(stackViewerComponent) {
    this.stackViewerComponent = stackViewerComponent;

    this.roiViewerElement = document.createElement("canvas");
    this.roiViewerElement.setAttribute("class", "roi_viewer");
    this.stackViewerComponent.imageStackElement.appendChild(this.roiViewerElement);

    this.roiCoordinates = $(this.stackViewerComponent.imageStackElement).data("coordinates");
    this.coverImageZIndex = $(this.stackViewerComponent.imageStackElement).data("cover-image-z-index");

    this.roiViewerElement.height = stackViewerComponent.componentSize;
    this.roiViewerElement.width = stackViewerComponent.componentSize;
    this.roiCtx = this.roiViewerElement.getContext("2d");
    this.roiCtx.strokeStyle = 'rgba(225,0,0,1)';
    this.roiCtx.lineWidth = 3;

    this.cameraImage = new Image();
    this.cameraImage.src = "data:image/svg+xml," + encodeURIComponent(cameraSvg);
  }


  redraw() {
    this.roiCtx.clearRect(0,0, this.roiViewerElement.width, this.roiViewerElement.height);

    this.drawRoi();
    this.drawCoverImage();
  };

  drawCoverImage() {
    const currentZIndex = this.stackViewerComponent.currentZIndex();

    if (currentZIndex === this.coverImageZIndex) {
      this.roiCtx.drawImage(this.cameraImage, 5, 0, 50, 50);
    };
  }

  drawRoi() {
    if (this.roiCoordinates == null || !this.stackViewerComponent.isReady()) {
      return;
    };

    const canvasTopLeft = this.stackViewerComponent.worldToCanvas([this.roiCoordinates.x0, this.roiCoordinates.y0, 1]);
    const canvasBottomRight = this.stackViewerComponent.worldToCanvas([this.roiCoordinates.x1, this.roiCoordinates.y1, 1]);

    var roiZLower, roiZUpper;
    [roiZLower, roiZUpper] = [this.roiCoordinates.z0, this.roiCoordinates.z1].sort((a,b) => a-b);
    const currentZIndex = this.stackViewerComponent.currentZIndex();

    if (currentZIndex >= roiZLower && currentZIndex <= roiZUpper) {
      if (currentZIndex == roiZLower || currentZIndex == roiZUpper) {
        this.roiCtx.setLineDash([]);
      } else {
        this.roiCtx.setLineDash([10, 7]);
      };

      this.roiCtx.strokeRect(
        canvasTopLeft[0],
        canvasTopLeft[1],
        canvasBottomRight[0] - canvasTopLeft[0],
        canvasBottomRight[1] - canvasTopLeft[1]
      );
    };
  };

  getRoiCoordinates() {
    return this.roiCoordinates;
  };

  setRoiCoordinates(newCoordinates) {
    this.roiCoordinates = newCoordinates;
    this.redraw();
  };

  setCoverImageZIndex(newCoverImageZIndex) {
    this.coverImageZIndex = newCoverImageZIndex
    this.redraw();
  };
};

import Chart from 'chart.js/auto';

export function init() {
  const charts = document.getElementsByClassName("statistics_chart");
  Array.from(charts).forEach((chart) => {
    init_chart(chart)
  });
};

function init_chart(element) {
  const ctx = element.getContext('2d');
  const chart_config = $(element).data("chart-config");

  console.log(chart_config);
  
  new Chart(ctx, chart_config);
}

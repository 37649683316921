import * as stackViewer from "./stackViewer"
import * as roiViewer from "./roiViewer"

export class EditRoiControls {

  constructor(element, stackViewerComponent, roiViewerComponent){
    this.editRoiControlsElement = element;
    this.stackViewerComponent = stackViewerComponent;
    this.roiViewerComponent = roiViewerComponent;

    var roiCoordinates = $(this.stackViewerComponent.imageStackElement).data("coordinates");
    this.roiPoints = [];
    if (roiCoordinates && roiCoordinates.x0) {
      this.roiPoints = [
        [
          roiCoordinates.x0,
          roiCoordinates.y0,
          roiCoordinates.z0
        ],
        [
          roiCoordinates.x1,
          roiCoordinates.y1,
          roiCoordinates.z1
        ]
      ];
    };

    $(this.stackViewerComponent.imageStackElement).click(this.handleStackClick.bind(this));
    $("#undo").click(this.handleUndoClick.bind(this));
    $("#clear").click(this.handleClearClick.bind(this));
    $("#set_cover_image").click(this.handleSetCoverImage.bind(this));
  };

  handleStackClick(event){
    if (
      event.originalEvent.getModifierState("Shift") ||
      event.originalEvent.getModifierState("Alt") ||
      event.originalEvent.getModifierState("Control") ||
      event.originalEvent.getModifierState("Meta")
    ){
      return
    };

    var clickedPoint = this.stackViewerComponent.canvasToWorld([
      event.originalEvent.layerX,
      event.originalEvent.layerY
    ]);
    clickedPoint[2] = this.stackViewerComponent.currentZIndex();
    this.roiPoints.push(clickedPoint);
    this.handlePointsUpdated();
  };

  handleUndoClick(event){
    event.preventDefault();
    this.roiPoints.pop();
    this.handlePointsUpdated();
  };

  handleClearClick(event){
    event.preventDefault();
    this.roiPoints = [];
    this.handlePointsUpdated();
  };

  handleSetCoverImage(event){
    this.coverImageZIndex = this.stackViewerComponent.currentZIndex();
    this.roiViewerComponent.setCoverImageZIndex(this.coverImageZIndex);
    this.updateRoiControlsForm();
  };

  handlePointsUpdated(){
    this.calculateRoiFromPoints(this.roiPoints);
    if (this.roi === undefined){
      this.roiViewerComponent.setRoiCoordinates(undefined);
    } else {
      this.roiViewerComponent.setRoiCoordinates({
        x0: Math.round(this.roi[0][0]),
        y0: Math.round(this.roi[0][1]),
        z0: Math.round(this.roi[0][2]),
        x1: Math.round(this.roi[1][0]),
        y1: Math.round(this.roi[1][1]),
        z1: Math.round(this.roi[1][2])
      });
    }
    this.updateRoiControlsForm();
  };

  calculateRoiFromPoints(points){
    this.roi = undefined;
    points.forEach((point) => {
      this.extendRoiByPoint(point);
    });
  };

  extendRoiByPoint(point) {
    if (this.roi === undefined) {
      this.roi = [point, point];
    } else {
      var previousRoiLower, previousRoiUpper;
      [previousRoiLower, previousRoiUpper] = this.roi;
      this.roi = [
        [
          Math.min(previousRoiLower[0], point[0]),
          Math.min(previousRoiLower[1], point[1]),
          Math.min(previousRoiLower[2], point[2]),
        ],
        [
          Math.max(previousRoiUpper[0], point[0]),
          Math.max(previousRoiUpper[1], point[1]),
          Math.max(previousRoiUpper[2], point[2]),
        ]
      ];
    };
  };

  updateRoiControlsForm(){
    if (this.roiPoints.length >= 2) {
      $("#volume_roi").val(JSON.stringify(this.roiViewerComponent.getRoiCoordinates()));
    } else {
      $("#volume_roi").val("{}");
    };
    $("#volume_cover_image_z_index").val(this.coverImageZIndex);
  };
};

import * as stackViewer from "./stackViewer"
import * as roiViewer from "./roiViewer"
import * as editRoiControls from "./editRoiControls"

const stackViewers = {};

export function init() {
  initImageStacks();
  initEditRoiControls();
};

function initImageStacks() {
  const imageStacks = document.getElementsByClassName("image_stack");
  Array.from(imageStacks).forEach((imageStackElement) => {
    initImageStack(imageStackElement);
  });
};

function initImageStack(imageStackElement) {
  const stackViewerComponent = new stackViewer.StackViewer(imageStackElement);

  const roiViewerComponent = new roiViewer.RoiViewer(stackViewerComponent);
  stackViewerComponent.addStackChangedEventListener(roiViewerComponent.redraw.bind(roiViewerComponent));

  // The stack viewer component needs to be re-initialized when a tab comes into the foreground
  // because cornerstone fails initialization when it's not visible
  $(".tabs").on("tabsactivate", function(event, ui) {
    stackViewerComponent.initRenderingEngineAndViewport();
  });

  stackViewers[imageStackElement.id] = {
    stackViewerComponent: stackViewerComponent,
    roiViewerComponent: roiViewerComponent
  };
};


function initEditRoiControls() {
  const editRoiControlsElement = document.getElementById("image_stack_controls");

  if (editRoiControlsElement) {
    const components = stackViewers[$(editRoiControlsElement).data("target")];
    new editRoiControls.EditRoiControls(
      editRoiControlsElement,
      components.stackViewerComponent,
      components.roiViewerComponent
    );
  }
};

// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// import "@hotwired/turbo-rails"
// import "./controllers"

import * as imageStack from "./imageStack"
import * as charts from "./charts"

$(document).ready(function() {
  charts.init();
  imageStack.init();
});

